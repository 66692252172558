/* DOCS : https://developer.mozilla.org/en-US/docs/Web/CSS/%40media/light-level */

/* The device is used in a environment with a light level in the ideal range for the screen, 
 * and which does not necessitate any particular adjustment.. */
@media (light-level: normal) {
 
}

/* The device is used in a dim environment, where excessive contrast and brightness would be 
 * distracting or uncomfortable to the reader. For example: night time, or a dimly 
 * illuminated indoor environment. */
@media (light-level: dim) {
  
}

/* The device is used in an exceptionally bright environment, causing the screen to be washed 
 * out and difficult to read. For example: bright daylight. */
@media (light-level: washed) {
 
}