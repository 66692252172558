/* Hierarchical Navigation */

.mod-nav-link:not(.nav-function-top):not(.nav-function-minify):not(.mod-hide-nav-icons) {

	ul.nav-menu:not(.nav-menu-compact) {

		> li {

			a {
				> .dl-ref:first-child {
					margin-left:0 !important;
				}
			}

			> ul {
				&:before {
					content: "";
					display: block;
					position: absolute;
					z-index: 1;
					left: $nav-padding-x + 0.5625rem;
					top: 44px;
					bottom: 0;
					border-left: 1px solid darken($nav-icon-color, 15%);
				}

				/* addressing all second, third children */
				> li {
					a {
						&:after {
							content: "";
							display: block;
							position: absolute;
							width: 0.4rem;
							height: 0.4rem;
							background-color: $nav-icon-color;
							left: $nav-padding-x + 0.4rem;
							top: unquote("calc(50% - 0.3rem)");
							border: 1px solid #333;
							border-radius: 50%;
							z-index: 1;
					
						}

						&:hover:after {
							border-color: transparent;
						}
					}

					li {
						> a {
							&:after{
								content: "";
								display: none;
							}
						}
					}
				}

				li {
					a {
						i {
							margin-left: 0 !important;
						}
					}
				}

			}

		}
	}
}