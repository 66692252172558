.page-breadcrumb {
	padding:0;
	background:transparent;
	margin:0 0 1.5rem;
	position: relative;
	text-shadow: $white 0 1px;
}

.breadcrumb {
	> li {

		> a {
			text-decoration: none !important;
		}

		&.breadcrumb-item {
			max-width: $page-breadcrumb-maxwidth - 70px;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;

			@extend %common-animation-slow;

			&:hover {
				max-width: $page-breadcrumb-maxwidth !important;
				cursor: default;
			}
		}
	}
}

[data-breadcrumb-seperator] + [data-breadcrumb-seperator]:before {
		content: attr(data-breadcrumb-seperator);
}

.breadcrumb-lg > li {
	font-size: rem($fs-xl);
}

.breadcrumb-sm > li {
	font-size: rem($fs-nano);
}

[class*='breadcrumb-seperator-'] .breadcrumb-item + .breadcrumb-item:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 1;
    font-family: Font Awesome\ 5 Pro;  
}

.breadcrumb-seperator-1 .breadcrumb-item + .breadcrumb-item:before { content:"\f105"; }
.breadcrumb-seperator-2 .breadcrumb-item + .breadcrumb-item:before { content:"\f178"; }
.breadcrumb-seperator-3 .breadcrumb-item + .breadcrumb-item:before { content:"\f054"; }

$breadcrumb-arrow-color: $fusion-100;
$breadcrumb-arrow-color-hover: $primary-500;

.breadcrumb-arrow {

	padding: 0;
	background: transparent;

	li{

		&.active {
			font-weight: 500;
			opacity: 0.5
		}

		a {
			@include text-contrast($breadcrumb-arrow-color)
			display:inline-block;
			background: $breadcrumb-arrow-color;
			text-decoration: none;
			position:relative;
			height: 2.5em;
			line-height: 2.5em;
			padding: 0 10px 0 5px;
			text-align: center;
			margin-right: 22px;
		}
		&:nth-child(even){
			a {
				background-color: $breadcrumb-arrow-color;

				&:before{
					border-color: $breadcrumb-arrow-color;
					border-left-color:transparent;
				}
				&:after{
					border-left-color: $breadcrumb-arrow-color;
				}
			}
		}
		&:first-child{
			a {
				padding-left:0.938em;

				border-radius: $border-radius 0 0 $border-radius;

				&:before{
					border: none;
				}
			}
		}
		&:last-child{
			a {
				padding-right:0.938em;

				border-radius: 0 $border-radius $border-radius 0;

					&:after{
						border: none;
					}
				}
			}

		a {   
			&:before,
			&:after{
				content: "";
				position:absolute;
				top: 0;
				border:0 solid $breadcrumb-arrow-color;
				border-width:1.250em 10px;
				width: 0;
				height: 0;
			}
			&:before{
				left:-20px;
					border-left-color:transparent;
				}
				&:after{
					left:100%;
					border-color:transparent;
					border-left-color: $breadcrumb-arrow-color;
				}
				&:hover{
					background-color: $breadcrumb-arrow-color-hover;

				&:before{
					border-color: $breadcrumb-arrow-color-hover;
					border-left-color:transparent;
				 }
				&:after{
					border-left-color: $breadcrumb-arrow-color-hover;
				}
			}
			&:active{
				background-color: $breadcrumb-arrow-color;

				&:before{
					border-color: $breadcrumb-arrow-color;
					border-left-color:transparent;
				}
				&:after{
					border-left-color: $breadcrumb-arrow-color;
				}
			}
		}
	}
}