
//$mod-main-boxed-width: map-get($grid-breakpoints, xxl);

@include media-breakpoint-up(xl) {

	.mod-main-boxed {
		.page-wrapper {
			max-width: $mod-main-boxed-width;
			margin: 0 auto;		
		}

		&:not(.nav-function-top) {
			
			#nff {
				position:relative;

				.onoffswitch-title {
					color: $settings-incompat-title;
				}
				.onoffswitch-title-desc {
					color: $settings-incompat-desc;
				}
				&:after {
					content: "DISABLED";
					display: block;
					position: absolute;
					background: $settings-incompat-bg;
					font-size: rem($fs-base - 3);
					width: 65px;
					text-align: center;
					border: 1px solid $settings-incompat-border;
					height: 22px;
					line-height: 20px;
					border-radius: $border-radius-plus;
					right: 13px;
					top: 26%;
					color:$fusion-900;
				}
			}
			
		}

		&.header-function-fixed {
			.page-wrapper {
				.page-header {
					width: 100%; //IE bug.
					max-width: $mod-main-boxed-width - 2px; //targets borders
					margin: 0 auto !important;
				}			
			}

			&:not(.nav-function-top):not(.nav-function-fixed) {
				.page-wrapper {
					.page-sidebar {
						position: absolute !important;
						top:0;
						bottom:0;
					}
					
				}
			}

			&:not(.nav-function-top):not(.nav-function-hidden):not(.nav-function-minify) {
				.page-wrapper {
					.page-header {
						padding-left: $nav-width + $header-inner-padding-x; 
					}
				}

				&:not(.nav-function-fixed) {
					.page-content {
						margin-left: $nav-width;
					}
				}
			}

			&.nav-function-minify:not(.nav-function-top):not(.nav-function-hidden) {
				.page-wrapper {
					.page-header {
						padding-left:$nav-minify-width + $header-inner-padding-x;
					}
					.page-content-wrapper {
						margin-left: $nav-minify-width;
					}
				}
			}

		}

		&.nav-function-hidden {

			&:not(.nav-function-top) {
				.page-sidebar:after {
					position:absolute;
					left: $nav-width;
				}
			}

			&.nav-function-minify {
				.page-sidebar:after {
					position:absolute;
					left: $nav-minify-width;
				}
			}			

		}

		/*
		why did we add this again?
		this was buggy when open modal with mod main boxed then click on logo for the dropdown, it won't close
		&.nav-function-fixed:not(.nav-function-top):not(.header-function-fixed) {
			.page-sidebar {
				transform: translateX(0) !important;
				box-shadow: none;
			}

			.page-wrapper {
				transform: translateX(0) !important;
			}

		}*/

		&.nav-function-fixed {
			&:not(.nav-function-top) {
				.page-wrapper {
					.page-sidebar {
						position:absolute;

						.page-logo {
							position:fixed;
							top:0;
							z-index: $cloud;
							@include box-shadow(0 2px 2px -1px rgba(0,0,0,.1));
						}

						.primary-nav {
							margin-top: $header-height;
						}
					}

				}

				&.nav-function-hidden {

					.page-logo {
						position: absolute !important;
						transition: none;
					}
				}
			}
		}

		.page-wrapper{
			border-left:1px solid rgba($black, .15);
			border-right:1px solid rgba($black, .15);
			box-shadow: 5px 0 20px 0px rgba(0, 0, 0, 0.1), -5px 0 20px 0px rgba(0, 0, 0, 0.1);
			overflow: hidden;
			position: relative;
		}

		&:not(.header-function-fixed):not(.nav-function-top):not(.modal-open):not(.panel-fullscreen) {
			.page-wrapper{
				transform: translateX(0) !important;
			}
		}

	}

}

